<template>
  <!-- xtEn6GvvcmZr9d3LxskWMSbaldanClientQuizID00 Baldan Client Quiz Key -->
  <!-- edodDrpzSDYRKMvgYiYVMS138600 Elth Client Quiz Key -->
  <div class="position-relative " style="font-family:montserrat">
    <div v-if="showAlert" class="AlertContainer">
      <div class="alert text-center fade show text-white alert-text" role="alert"
        style="background: #f27367; width: fit-content">
        <span><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
          {{ alertMsg }}
        </span>
      </div>
    </div>
    <!--  -->
    <div v-if="isResponse">
      <!--  -->
      <div v-if="collectionLink" class="mt-5 w-100 h-100 d-flex flex-column justify-content-between align-items-center">
        <div class="mb-4">
          <LazyImage :src="`https://images.quizell.com/website/Frame%20(6)%20(1).png`" style="width: 100%; height: 200px"
            :alt="`Quizell Product Quiz`" :title="`Quizell Product Quiz`" class="rounded successImage" />
        </div>
       
        <p style="
                  font-family: 'montserrat';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 27px;
                  text-align: center;
                  color: #503a3c;
                ">

          {{ translateCustomText('YAYY!') }}
        </p>
        <div class="w-md-75 mx-4">
          <p class="" style="
                  font-family: 'montserrat';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  text-align: center;
                  color: rgb(255, 102, 58);;
                  
                ">
          {{ translateCustomText('...your new shop page is ready! Click the button below to view it.') }}
          <!-- ...your new shop page is ready! Click the button below to view it. -->
        </p>
        </div>
        

        <button v-if="quizId == baldanClientQuizID" style="
                  width: 204px;
                  height: 44px;
                  background: #a9411f;
                  border: 1px solid #a9411f;
                  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                  border-radius: 52px;
                " @click="openSubscriptionQuiz()">
          <span style="
                    font-family: 'montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                  ">
            {{ translateCustomText('Pay Now') }}

          </span>
        </button>
        <button v-else style="
                  width: 204px;
                  height: 44px;
                  background: #a9411f;
                  border: 1px solid #a9411f;
                  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                  border-radius: 52px;
                " @click="openExternalLink(collectionLink)">
          <span style="
                    font-family: 'montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                  ">
            {{ translateCustomText('View page') }}

          </span>
        </button>
        <div style="text-align: center" class="mt-3" v-if="quizId == baldanClientQuizID">
          <button @click="openCurrentLink(collectionLink)" style="
                    width: 204px;
                    height: 44px;
                    background: transparent;
                    border: 2px solid #9c4629;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 52px;
                  ">
            <span style="
                      font-family: 'montserrat';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #a9411f;
                    ">
              {{ translateCustomText('View shop ') }}
            </span>
          </button>
        </div>
        <div style="text-align: center" class="mt-3" v-else>
          <button @click="restartQuiz" style="
                    width: 204px;
                    height: 44px;
                    background: transparent;
                    border: 2px solid #9c4629;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 52px;
                  ">
            <span style="
                      font-family: 'montserrat';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #a9411f;
                    ">
              {{ translateCustomText('Restart') }}
            </span>
          </button>
        </div>

        <div style="text-align:center" class="mt-3" v-if="quizId != baldanClientQuizID">
          <button class="btn" style="
                  font-family: 'montserrat';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  text-align: center;
                  color: #FF663A;
                  cursor:pointer;
                " @click="copyLink(collectionLink)"> {{ translateCustomText('Copy shop link') }}</button>
        </div>
      </div>
      <div v-else>
        <div v-if="errorMsgType == 'affiliate_error'"
          class="w-100 mt-5 h-100 d-flex flex-column justify-content-between align-items-center">
          <div class="mb-4">
            <LazyImage :src="`https://images.quizell.com/website/Elth-affiliate-error.png`"
              style="width: 100%; height: 350px" :alt="`Quizell Product Quiz`" :title="`Quizell Product Quiz`"
              class="rounded" />
          </div>
          <p style="
                    font-family: 'montserrat'
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;
                    text-align: center;
                    color: #503a3c;
                  ">
            Oops!
          </p>
          <p style="
                    font-family: 'montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    color: #FF663A;
                  ">
            {{ translateCustomText('Hi Elth user! We noticed that you are already have a shop. Now choose if you want to...') }}

          </p>

          <button style="
                    width: 204px;
                    height: 44px;
                    background: #a9411f;
                    border: 1px solid #a9411f;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 52px;
                  " @click="
                    openExternalLink(
                      'https://elth.co.uk/pages/join-us#shopify-section-template--16703907528929__b67d8ef8-2a80-4684-838b-e59cc5c9dff0'
                    )
                  ">
            <span style="
                      font-family: 'montserrat';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #ffffff;
                    ">
              Sign up
            </span>
          </button>
          <div style="text-align: center" class="mt-3">
            <button @click="restartQuiz" style="
                      width: 204px;
                      height: 44px;
                      background: transparent;
                      border: 2px solid #9c4629;
                      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                      border-radius: 52px;
                    ">
              <span style="
                        font-family: 'montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #a9411f;
                      ">
                {{ translateCustomText('Restart') }}


              </span>
            </button>
          </div>
        </div>
        <div v-else class="w-100 h-100 d-flex flex-column justify-content-between align-items-center">
          <p :style="getTitleStyles(ProductStyles)" style="font-size: 30px !important">
            Opps Something went wrong!!
          </p>
          <p :style="getTitleStyles(ProductStyles)" style="font-size: 20px !important" v-if="Array.isArray(errorMsg)">
            {{ errorMsg[0] }}
          </p>
          <p :style="getTitleStyles(ProductStyles)" style="font-size: 20px !important" v-else>
            {{ errorMsg }}
          </p>
          <button :style="getButtonCss(buttonStyle)" @click="CloseErrorModal">
            <span :style="{
              opacity: `${buttonStyle.textOpacity}%`,
              fontSize: `${buttonStyle.fontSize}px`,
            }">
              {{ translateCustomText('Go Back') }}

            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="" style="width: 100%;height:100%;">
      <div class="d-flex flex-column ">

        <div style="max-height:458px;overflow:scroll">
          <!-- Title Input -->
          <div class="">
            <div class="" style="text-align: end">
              <button @click="openElthInfo()" class="btn">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.8584 3.78076C14.5439 3.46631 14.1714 3.30908 13.7407 3.30908C13.3101 3.29541 12.9375 3.45264 12.623 3.78076C12.3086 4.09521 12.1514 4.46777 12.1514 4.89844C12.1514 5.32227 12.3086 5.69141 12.623 6.00586C12.9375 6.31348 13.3101 6.4707 13.7407 6.47754C14.1714 6.4707 14.5439 6.31348 14.8584 6.00586C15.1729 5.69141 15.3301 5.32227 15.3301 4.89844C15.3301 4.46777 15.1729 4.09521 14.8584 3.78076ZM11.6387 12.4761C11.4609 13.3101 11.208 14.4312 10.8799 15.8394C10.6611 16.7554 10.7329 17.439 11.0952 17.8901C11.4438 18.314 11.9189 18.5464 12.5205 18.5874C13.9424 18.6899 14.7456 17.6235 14.9302 15.3882H14.438C14.1304 17.0288 13.7441 17.8013 13.2793 17.7056C13.0059 17.6509 12.9785 17.1416 13.1973 16.1777L14.3354 11.1738C14.5405 10.2373 14.4688 9.55371 14.1201 9.12305C13.7715 8.69238 13.2964 8.45996 12.6948 8.42578C11.2798 8.32324 10.4766 9.38965 10.2852 11.625H10.7773C11.085 9.97754 11.4712 9.20508 11.936 9.30762C12.2163 9.3623 12.2437 9.87158 12.0181 10.8354L11.6387 12.4761Z"
                    fill="#9C4629" />
                  <circle cx="12.5" cy="12.5" r="11" stroke="#9C4629" stroke-width="3" />
                </svg>
              </button>
            </div>
            <div class=" text-uppercase" :style="{
              fontSize: headingStyle.fontSize + 'px',
              'font-weight': headingStyle.fontWeight, margin: `${headingStyle.marginTop}px ${headingStyle.marginRight}px ${headingStyle.marginBottom}px ${headingStyle.marginLeft}px`,
              lineHeight: headingStyle.lineHeight, textAlign: headingStyle.textAlign, opacity: headingStyle.opacity, color: headingStyle.color,
              padding: `${headingStyle.paddingTop}px ${headingStyle.paddingRight}px ${headingStyle.paddingBottom}px ${headingStyle.paddingLeft}px`
            }">
              {{ translateCustomText('Create your shop') }}

            </div>
          </div>
          <div class="d-flex w-100 align-items-end">


            <div :style="
              style.borderType === 'border'
                ? getInputBorderCSS(style)
                : getInputBorderBottomCSS(style)
            " class="d-flex w-100  align-items-end" style="margin: 0 20px">
              <input :placeholder="translateCustomText('enter the title of your shop page here (max 30 characters)')"
                class="font-weight-medium input-element CustomTextInputCollection" :style="customInputStyles(style)"
                v-model="title" />
              <div class="text-right pr-3 mt-1">
                <span class="text-nowrap" :style="title.length < 30 ? 'color:#503a3c' : 'color:#f50d22'">{{
                  title.length
                }}/30</span>
              </div>
            </div>


          </div>

          <div class="mt-2">
            <div :style="getMarginCss(style) + getTextAlign(style)">
              <div class="d-flex align-items-end w-100" :style="
                style.borderType === 'border'
                  ? getInputBorderCSS(style)
                  : getInputBorderBottomCSS(style)
              ">
                <input :placeholder="translateCustomText('enter the description of your shop page here (max 400 characters)')"
                  class="font-weight-medium input-element CustomTextInputCollection" :style="customInputStyles(style)"
                  v-model="description" />
                <!-- <textarea
              type="text"
              v-model="description"
              :style="freeTextInputStyles(style)"
              id="textInput"
              placeholder="Your shop page’s description (max 500 characters)"
              style="resize: none; overflow: auto"
              class="productDescription-input"
              @keyup="InputAdjust"
              @focus;="InputAdjust"
            /> -->
                <div class="text-right pr-2 mt-1 text-nowrap">
                  <span :style="description.length < 400 ? 'color:#503a3c' : 'color:#f50d22'">{{
                    description.length
                  }}/400</span>
                </div>
              </div>
            </div>
          </div>
          <div class="">

            <div class="text-uppercase m-4" :style="{
              fontSize: headingStyle.fontSize + 'px',
              'font-weight': headingStyle.fontWeight, margin: `${headingStyle.marginTop}px ${headingStyle.marginRight}px ${headingStyle.marginBottom}px ${headingStyle.marginLeft}px`,
              lineHeight: headingStyle.lineHeight, textAlign: headingStyle.textAlign, opacity: headingStyle.opacity, color: headingStyle.color,
              padding: `${headingStyle.paddingTop}px ${headingStyle.paddingRight}px ${headingStyle.paddingBottom}px ${headingStyle.paddingLeft}px`
            }">

              {{ translateCustomText('ADD PRODUCTS TO YOUR SHOP') }}
            </div>
          </div>
          <div class="elth-filters" style="margin: 0 20px">

            <div :style="
              style.borderType === 'border'
                ? getInputBorderCSS(style)
                : getInputBorderBottomCSS(style)
            ">
              <input :placeholder="translateCustomText('Search for a product title, brand or code')"
                class="font-weight-medium input-element CustomTextInputCollection" :style="customInputStyles(style)"
                v-model="filter_text" />
            </div>
            <div class="row">
              <div class="col-md-4 mt-3"> <b-form-select v-model="selectedType">
                  <b-form-select-option class="elth-select-option" value=""><label>{{ translateCustomText(
                    'Type') }}</label></b-form-select-option>
                  <!-- <b-form-select-option  class="elth-select-option" value=""><label>{{translateCustomText( 'All')}}</label></b-form-select-option> -->
                  <b-form-select-option class="elth-select-option" :value="ProductType"
                    v-for="ProductType in  productType" :key="ProductType">{{ ProductType
                    }}</b-form-select-option>

                </b-form-select>
              </div>
              <div class="col-md-6 mt-3"> <b-form-select v-model="selectedVendor">
                  <b-form-select-option class="elth-select-option" value=""><label>{{ translateCustomText(
                    'Brand') }}</label></b-form-select-option>
                  <b-form-select-option class="elth-select-option" :value="vendor" v-for="vendor in  productVendors"
                    :key="vendor">{{ vendor }}</b-form-select-option>
                </b-form-select>
              </div>
              <div class="col-md-2 px-0 mt-3">
                <button v-if="showResetFilterBtn"
                    class="btn m-0"
                    @click="resetFilter()"
                    style="
                      border: 1px solid #9c4629;
                     
                      font-size: 16px;
                      text-align: center;
                      font-weight: 700;
                      color: #9c4629;
                      min-width: 114px;
                    "
                  >
                    
                    {{ translateCustomText('Reset') }}
                  </button>
                </div>
            </div>
          </div>
          <div class="" style="margin: 0 20px">
            <div class="d-flex justify-content-between mb-1">
              <p class="mb-1 mt-2" style="
                    color: #503a3c;
                    font-family: 'montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                  ">
                {{ translateCustomText('N° Products Selected') }}
                =
                <span style="color: #9c4629; font-weight: 700">{{
                  selectedProducts.length
                }}</span>
              </p>
              <p class="mb-1 mt-2" style="
                    color: #503a3c;
                    font-family: 'montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                  ">
                {{ translateCustomText('N° Products') }}
                =
                <span style="color: #9c4629; font-weight: 700">{{
                  Products.length
                }}</span>
              </p>
            </div>
            <div :style="getScrollCss()">
              <table class="table table-striped text-center table-sm ResponsiveTable" id="ClientProductTable">
                <thead class="py-3 w-100 stickyHeader" style="background: #503a3c; color: #ffffff">
                  <tr>
                    <th scope="col" style="text-align: center">
                      <div class="ProductInfo">
                        <b-form-checkbox id="SelectAllCheckboxResultPage" v-model="selectAllProducts" name="SelectAll"
                          class="SelectAllCheckboxResultPage" @change="selectAllProductsEvent($event)">
                        </b-form-checkbox>
                      </div>
                    </th>
                    <th scope="col" class="ProductInfo" style="font-family: Open Sans;white-space: pre-wrap;">

                      {{ translateCustomText('Image') }}
                    </th>
                    <th scope="col" class="ProductInfo" style="font-family: Open Sans">

                      {{ translateCustomText('Title') }}
                    </th>
                    <th scope="col" class="ProductInfo min-width-tr" style="font-family: Open Sans">

                      {{ translateCustomText('Price') }}
                    </th>
                    <th scope="col" class="ProductInfo min-width-tr" style="font-family: Open Sans">

                      {{ translateCustomText('Brand') }}
                    </th>
                    <th scope="col" class="ProductInfo min-width-tr" style="font-family: Open Sans" v-if="quizId == baldanClientQuizID">

                      {{ translateCustomText('Code') }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in getProductsTable" :key="`${item.id}-${index}`" @click.stop="selectProduct($event,item)">
                    <td style="vertical-align: middle; text-align: center; min-width: 100px">
                      <div class="ProductInfo ">
                        <b-form-checkbox :id="`${item.id}-${Date.now()}`" v-model="selectedProducts"
                          :name="`${item.id}-${Date.now()}`" :value="checkConvertIntegar(item.external_id)"
                          @change="toggleSingleProduct" style="cursor: pointer" class="ProductItemCheckbox">
                        </b-form-checkbox>
                       
                      </div>
                    </td>

                    <td class="ProductInfo" style="vertical-align: middle">
                      <LazyImage :src="ImageSrc(item.image)" :key="`${index}-${item.id}`" style="
                          height: 80px;
                          width: 80px;
                          border-radius: 12px;
                          cursor: pointer;
                        " alt="" />
                    </td>
                    <td style="vertical-align: middle">
                      <p class="ProductTitle ProductInfo m-0" style="cursor: pointer"
                        :style="getTitleStyles(ProductStyles)" @click.stop="openProduct(item)">
                        {{ item.title }}
                      </p>
                    </td>
                    <td style="vertical-align: middle">
                      <p class=" ProductInfo m-0" style="cursor:context-menu"
                        :style="getTitleStyles(ProductStyles)">
                        <span></span>
                        {{ quizId==baldanClientQuizID?'&euro;':'&pound;' }} {{ item.price }}
                      </p>
                    </td>
                    <td style="vertical-align: middle" :style="[quizId==baldanClientQuizID?{cursor:'context-menu' }:{cursor:'pointer'}]">
                      <p :class="[quizId == baldanClientQuizID?'':'ProductTitle',' ProductInfo m-0']" 
                        :style="getTitleStyles(ProductStyles)" @click.stop="openVendor(item.vendor)">
                        {{ item.vendor }}
                      </p>
                    </td>
                    <td style="vertical-align: middle" v-if="quizId == baldanClientQuizID">
                      <p class=" ProductInfo m-0" style="cursor: context-menu"
                        :style="getTitleStyles(ProductStyles)">
                        {{ item.sku }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class=" d-flex justify-content-center">
          <button :style="getButtonCss(createButtonStyle)" style=" border-radius: 40px !important;" @click="submitData" :disabled="submitSpinner" >
            <span class="text-capitalize" v-if="!submitSpinner" :style="{
              opacity: `${buttonStyle.textOpacity}%`,
              fontSize: `${buttonStyle.fontSize}px`,
            }">

              {{ translateCustomText('Create shop') }}
            </span>

            <div v-else class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>

        
          <!-- <button style="
              border: 2px solid #9c4629;
              padding: 15px;
              border-radius: 40px;
              height: 42px;
              width: 150px;
              margin: 10px 10px 10px 22px;
              display: flex;
              align-items: center;
              text-align: center;
              font-family: Poppins;
              justify-content: center;
              font-weight: 700;
            " class="ml-3" @click="restartQuiz">
            <span class="text-capitalize" :style="{
              opacity: `${buttonStyle.textOpacity}%`,
              fontSize: `${buttonStyle.fontSize}px`,
              color: `#9C4629`,
            }">
             {{translateCustomText('Restart quiz')}}
              
            </span>
          </button> -->
        </div>
        <div v-if="quizId == 2492 && submitSpinner" class="d-flex justify-content-center mt-2" :style="{
              fontSize: '15px',
              'font-weight': headingStyle.fontWeight, margin: `${headingStyle.marginTop}px ${headingStyle.marginRight}px ${headingStyle.marginBottom}px ${headingStyle.marginLeft}px`,
              lineHeight: headingStyle.lineHeight, textAlign: headingStyle.textAlign, opacity: headingStyle.opacity, color: headingStyle.color,
              padding: `${headingStyle.paddingTop}px ${headingStyle.paddingRight}px ${headingStyle.paddingBottom}px ${headingStyle.paddingLeft}px`
            }">
            <p class="m-0">Il processo di creazione del negozio può richiedere alcuni minuti, si prega di non chiudere questa finestra per evitare di perdere i dati.</p>
        </div>


      </div>

    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
import TranslationMixin from "../customize/mixin/TranslationMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [cssFunctionMixin, TranslationMixin],
  props: ["Products", "ProductStyles", "apiToken", "pageHeight", "quizId", "headingStyle"],

  data() {
    return {

      productType: [],
      productVendors: [],
      selectedVendor: "",
      selectedType: "",
      style: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 20,
        marginRight: 20,
        textOpacity: 100,
        color: "#9C4629",
        textDecoration: "unset",
        fontWeight: "normal",
        fontStyle: "normal",
        textAlign: "left",
        fontSize: 14,
        fontFamily: "Poppins",
        strokeOpacity: 100,
        strokeColor: "#9C4629",
        strokeRotation: 0,
        borderSize: 3,
        borderRadius: 0,
        borderType: "border-bottom",
        sendBtnFontWeight: "normal",
        sendBtnFontFamily: "Poppins",
        sendBtnFontSize: 14,
        sendBtnFontStyle: "normal",
        sendBtnTextDecoration: "unset",
        sendBtnBGColor: "#000000",
        sendBtnColor: "#ffffff",
        sendBtnWidth: 150,
        sendBtnHeight: 45,
      },
      buttonStyle: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: "#9C4629",
        color: "#ffffff",
        textAlign: "center",
        fontWeight: "bold",
        fontStyle: "normal",
        buttonWidth: 150,
        buttonHeight: "42",
        widthIsAuto: true,
        borderRadius: "40",
        borderSize: "0",
        borderColor: "",
        position: "center",
        textOpacity: 100,
        backgroundOpacity: 100,
        fontSize: "16",
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 22,
        marginRight: 10,
        lineHeight: 1,
        textDecoration: "unset",
        fontFamily: "Poppins",
      },
      createButtonStyle: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "#9C4629",
        color: "#ffffff",
        textAlign: "center",
        fontWeight: "bold",
        fontStyle: "normal",
        buttonWidth: 200,
        buttonHeight: "42",
        widthIsAuto: true,
        borderRadius: "40",
        borderSize: "0",
        borderColor: "",
        position: "center",
        textOpacity: 100,
        backgroundOpacity: 100,
        fontSize: "16",
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 22,
        marginRight: 10,
        lineHeight: 1,
        textDecoration: "unset",
        fontFamily: "Poppins",
      },
      products: [],
      title: '',
      filter_text: '',

      description: '',
      selectedProducts: [],
      selectAllProducts: false,
      submitSpinner: false,
      isResponse: false,
      errorMsgType: null,
      collectionLink: null,
      errorMsg: null,
      SuccessMsg: "Congratulations! Your collection has been created.",
      showAlert: false,
      alertMsg: ""
    };
  },
  watch: {
    Products: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.products = newValue;
      
      },
    },
    description(newVal, oldVal) {
      if (
        newVal.length > 400
      ) {
        this.description = oldVal;
      }
    },
    title(newVal, oldVal) {
      if (
        newVal.length > 30
      ) {
        this.title = oldVal;
      }
    },
    // selectedType(newVal,oldVal){
    //   if(newVal && newVal != oldVal){
    //       this.selectAllProducts = false;
    //   } 
      
    // }
   
  },

  methods: {
    resetFilter(){
      // this.selectAllProducts = false;
      this.selectedVendor=''; 
      this.selectedType=''; 
      this.filter_text=''
    },
    openSubscriptionQuiz() {
      window.open('/quiz/wzj9TeoWwbacxXvWSamEMS249000', '_self')
    },
    translateCustomText(key) {
      return this.translateToItalian(key, this.quizId)
    },

    copyLink(link) {
      if (link) {
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = link;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.$toasted.show(this.translateCustomText(`Link copied!`), {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    //  async copyLink(link){
    //     if(link){
    //        await navigator.clipboard.writeText(link);
    //     this.$toasted.show("Link Copied ", {
    //       theme: "toasted-primary",
    //       position: "bottom-center",
    //       duration: 2000,
    //     });
    //     }
    //   },

    openExternalLink(link) {
      window.open(link, "_blank");
    },
    openCurrentLink(link){
      window.open(link, "_blank");
    },
    getScrollCss() {
      return `max-height:310px;height:100%;   overflow-y: scroll;
  scroll-behavior: smooth;`;
    },
    CloseErrorModal() {
      this.collectionLink = null;
      this.errorMsgType = null;
      this.isResponse = false;
      this.errorMsg = null;
      // $("#ClientErrorModal").modal("hide");
    },
    OpenErrorModal() {
      $("#ClientErrorModal").modal("show");
    },
    resetData() {
      // this.products = [];
      this.title = null;
      this.description = null;
      this.selectedProducts = [];
    },
    openProduct(product) {
      if (product.detail_link) {
        window.open(product.detail_link, "_blank");
      }
    },
    async submitData() {
      if (this.isSubmitValid) {
        this.submitSpinner = true;
        try {
          let data = {
            title: this.title,
            description: this.description,
            // products: JSON.stringify(this.selectedProducts),
            products: this.selectedProducts,
            email: this.getSavedLeadID.email,
            api_token: this.apiToken,
          };
          if (this.lastOption) {
            data["image"] = this.lastOption.selectedOption.image;
            // data['image'] =  'https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Desktop_01_1.png?v=1671799809https://cdn.shopify.com/s/files/1/0571/0246/7222/files/Desktop_01_1.png?v=1671799809'
          }

          const response = await axios.post(`/api/custom/collection/store`, data);

          if (response.status == 200 && response.data.status == "success") {
            this.isResponse = true;
            this.collectionLink = response.data.data;
            this.resetData();
          } else {
            if (
              response.status == 201 &&
              response.data.status == "affiliate_error"
            ) {
              this.isResponse = true;
              this.errorMsgType = response.data.status;
              this.errorMsg = response.data.data;
            } else {
              this.isResponse = true;
              this.errorMsgType = response.data.status;
              this.errorMsg = response.data.data;
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show(this.translateCustomText(`An error has occurred`), {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        } finally {
          this.submitSpinner = false;
        }
      }
      else {
        this.title && this.selectedProducts.length;

        this.alertMsg = "Please provide required values.";




        this.showAlert = true;
        setTimeout(
          function () {
            this.showAlert = false;
            this.alertMsg = "";
          }.bind(this),
          3000
        );
      }


    },
    restartQuiz() {
      window.location.href = this.$router.history.current.path;
    },
    restartQuizUpdate() {

      if (!localStorage.getItem("already_record_email")) {
        localStorage.setItem("already_record_email", this.getSavedLeadID.email)
        localStorage.setItem("already_record_status", true)
        this.$emit('changeToUpdateElth')
      }
    },

    getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    toggleSingleProduct() {
      this.selectAllProducts = false;
    },
    async selectAllProductsEvent(e) {
      if (e) {
        try {
          this.selectedProducts = await this.getProductsTable.map((x) =>
            this.checkConvertIntegar(x.external_id)
          );

          // this.selectedProducts = [...this.selectedProducts , productsToAdd]
        } catch (error) {
          if (error) {
            this.$toasted.show(this.translateCustomText(`An error has occurred`), {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        }
      } else {
        this.selectedProducts = [];
      }
    },
    generatProducts() {
      let products = [];
      for (let i = 0; i < 40; i++) {
        const id = `${i}`;
        const title = `Product-${i}`;
        const price = `${i * 100}`;
        const image = `https://images.unsplash.com/photo-1643185450492-6ba77dea00f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80`;
        const vendor = `vendor-${i}`;
        products.push({ id, title, price, image, vendor });
        this.products = products;
      }
    },
    ImageSrc(img) {
      if (img) return img;
      return 'https://images.quizell.com/website/default_start_page.png';
    },
    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    InputAdjust(e) {
      let Element = e.target;
      Element.rows = 2;
      const styles = window.getComputedStyle(Element);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      const padding = paddingTop + paddingBottom;
      const initialHeight = (parseInt(styles.height) - padding) / Element.rows;
      const scrollHeight = Element.scrollHeight - padding;
      const newRows = Math.ceil(scrollHeight / initialHeight);
      Element.rows = newRows - 1;
    },
    checkConvertIntegar(value) {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return parseInt(value, 10);
      }
    },
    openVendor(vendor) {
      
      if (vendor&&this.quizId!=this.baldanClientQuizID) {
        let newVendor = vendor.replace(/ /g, '-')
        let url = `https://elth.co.uk/collections/${newVendor}`;
        window.open(url, "_blank");
      }
    },
    openElthInfo() {
      window.open(`https://elth-store.freshdesk.com/support/home`, "_blank");
    },
    async getTypesAndVendors() {
      this.productVendors=[...new Set(this.products.map(item => item.vendor))]
     this.productType=[...new Set(this.products.map(item => item.product_type))]
    },
    selectProduct(event,product){

    
       this.selectAllProducts = false;
        let allowedTarget = ['DIV','TD']

      let productId = this.checkConvertIntegar(product.external_id)
      if (allowedTarget.includes(event.target.tagName)){
         const index = this.selectedProducts.findIndex(
        (id) => id == productId
      );
      if(index > -1){
        this.selectedProducts.splice(index, 1)
      } else{
         this.selectedProducts.push(productId)
      }
      }

     
    }
  },
  computed: {
    showResetFilterBtn(){
    return this.selectedVendor!==''||this.selectedType!==''||this.filter_text!==''?true:false; 
    },
    getSavedUser() {
      return this.getSavedLeadID
    },
    ...mapGetters(["getSavedLeadID", "getSelectedOptions","getEnvConst"]),
             getEnv(){
      // ...mapGetters(["getEnvConst"]),
      // getEnvConst
      return this.getEnvConst;
    },
      baldanClientQuizID() { return this.getEnv?.VUE_APP_ITALIAN_QUIZID??null},
    getProductsTable() {
      return this.products.filter(product => {

        return (
          ((product.title != null && product.title.toLowerCase().includes(this.filter_text.trim().toLowerCase()))
            || (product.vendor != null && product.vendor != null && product.vendor.toLowerCase().includes(this.filter_text.trim().toLowerCase()))
            || (product.sku != null && product.sku.toLowerCase().includes(this.filter_text.trim().toLowerCase()))) &&
          ((product.vendor != null && product.vendor.includes(this.selectedVendor)) && ((product.product_type != null && product.product_type.includes(this.selectedType))))

        )
      });
    },
    lastOption() {
      let length = this.getSelectedOptions.questionAnswer.length;
      return this.getSelectedOptions.questionAnswer[length - 1];
    },
    isSubmitValid() {
      return this.title && this.selectedProducts.length;
    },
  },

  mounted() {
    
       
    this.getTypesAndVendors()
    // $(this.$refs.ClientErrorRef).on("show.bs.modal", this.OpenErrorModal);
    // $(this.$refs.ClientErrorRef).on("hidden.bs.modal", this.CloseErrorModal);
  },
};
</script>

<style scoped>
.input-element {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.CustomTextInputCollection {
  /* color: var(--inputColor) !important; */
  color: #503a3c !important;
  font-size: 18px !important;
}

.productDescription-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #503a3c !important;
  font-size: 18px !important;
  /* color: var(--inputTextColor); */
}

.productDescription-input::placeholder,
.input-element::placeholder {
  /* color: var(--inputPlaceholderColor); */
  color: #503a3c;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.productDescription-input::placeholder {
  font-size: 18px !important;
}

/* .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before */
.SelectAllCheckboxResultPage>>>#SelectAllCheckboxResultPage.custom-control-input:checked~label::before {
  background: #9c4629 !important;
}

.ProductItemCheckbox>>>.custom-control-input:checked~label::before {
  background: #9c4629 !important;
}

.custom-select:focus {
  border-color: #9c4629;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #9c462979;
}

.ProductInfo {
  text-align: center !important;
}

.custom-select:focus option:checked {
  background-color: #9c4629 !important;
  color: rgb(255, 255, 255);
}

option:checked {
  background-color: #9c4629 !important;
  color: rgb(255, 255, 255);
}

option:hover {
  box-shadow: 0 0 10px 100px #9c4629 inset;
  background-color: #9c4629 !important;
  color: rgb(255, 255, 255);
}

.ProductTitle:hover {
  text-decoration: underline !important;
}

#ClientProductTable td {
  border-top: 1px solid #503a3c !important;
}

.ClientErrorModalContent {
  border-radius: 20px;
  text-align: left;
}

.Title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}

.TitleSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}

.btn-tab {
  background: transparent;
  border: 1px solid #cbcddc;
  color: #4d4950;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 4.5px;
}

.btn-tab.active {
  background: #4d1b7e;
  border: 1px solid #4d1b7e;
  color: #ffffff;
}

.w-90 {
  width: 90%;
}

@media screen and (max-width: 750px) {
  .ProductInfo {
    margin-bottom: 0 !important;
  }

  .ResponsiveTable thead {
    display: none;
  }

  .ResponsiveTable,
  .ResponsiveTable tbody,
  .ResponsiveTable tr,
  .ResponsiveTable td {
    display: block;
    width: 100%;
  }

  .ResponsiveTable tr {
    margin-bottom: 15px;

  }

  #ClientProductTable td {
    border-top: none !important;
  }

  .ResponsiveTable td {
    text-align: center;
    /* padding-left: 50%; */
    position: relative;
  }

  .ResponsiveTable td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    text-align: left;
  }
}

.min-width-tr {
  min-width: 100px;
}

.stickyHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
}

@media only screen and (max-width: 550px) {
  .successImage {
    object-fit: contain;
  }
}

@media only screen and (min-width: 768px) {
  .w-md-75 {
    width:75%;
  }
}
</style>
